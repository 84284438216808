html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Garamond';
  src: local('Garamond'),
    url("./fonts/garamond.light.ttf") format("truetype");
  font-weight: light;
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'),
    url("./fonts/Quicksand.ttf") format("truetype");
  font-weight: light;
}

@font-face {
  font-family: 'PT';
  src: local('PT'),
    url("./fonts/PT.ttf") format("truetype");
  font-weight: bold;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.destinations,
.experiences,
.retreats,
.not-found,
.about,
.terms,
.privacy,
.contact,
.rentals {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 80px;
  font-weight: 100;
  text-align: center;
  font-family: 'Garamond', sans-serif;
}

.experiences {
  background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/experiences.jpg');
}

.destinations {
  background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/destinations.jpg');
}

.retreats {
  background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/retreats.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.rentals {
  background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/rentals.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.terms,
.privacy,
.not-found {
  background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/not-found.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.about {
  background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/about.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.contact {
  background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/contact.jpg');
  background-size: cover;
  font-size: 1px;
  background-repeat: no-repeat;
}

@media (max-width: 1024px) {
  .about {
    background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/about-mobile.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .rentals {
    background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/rentals-mobile.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .terms,
  .privacy,
  .not-found {
    background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/not-found-mobile.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .experiences {
    background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/experiences-mobile.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .retreats {
    background: linear-gradient(180deg, rgba(32, 32, 32, 0.7301295518207283) 0%, rgba(255, 255, 255, 0.010241596638655426) 20%, rgba(255, 255, 255, 0) 100%), url('./images/guards/retreats-mobile.jpg');
  }
}

@media (max-width: 1024px) {

  .destinations,
  .contact,
  .retreats,
  .not-found,
  .about,
  .terms,
  .privacy,
  .experiences,
  .rentals {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1024px) {

  .destinations,
  .not-found,
  .contact,
  .retreats,
  .about,
  .terms,
  .privacy,
  .experiences,
  .rentals {
    font-size: 42px;
    text-align: center;
  }
}

.loading {
  background: #F3F2EE;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}